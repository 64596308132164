export const REQUEST_MASTER_PRICE = 'REQUEST_MASTER_PRICE';
export const REQUEST_MASTER_PRICE_SUCCESS = 'REQUEST_MASTER_PRICE_SUCCESS';
export const REQUEST_MASTER_PRICE_FAILURE = 'REQUEST_MASTER_PRICE_FAILURE';

export const REQUEST_QUOTA = 'REQUEST_QUOTA';
export const REQUEST_QUOTA_SUCCESS = 'REQUEST_QUOTA_SUCCESS';
export const REQUEST_QUOTA_FAILURE = 'REQUEST_QUOTA_FAILURE';

export const REQUEST_HISTORY_PAYMENT = 'REQUEST_HISTORY_PAYMENT';
export const REQUEST_HISTORY_PAYMENT_SUCCESS = 'REQUEST_HISTORY_PAYMENT_SUCCESS';
export const REQUEST_HISTORY_PAYMENT_FAILURE = 'REQUEST_HISTORY_PAYMENT_FAILURE';

export const REQUEST_USED_KEYSIGN = 'REQUEST_USED_KEYSIGN';
export const REQUEST_USED_KEYSIGN_SUCCESS = 'REQUEST_USED_KEYSIGN_SUCCESS';
export const REQUEST_USED_KEYSIGN_FAILURE = 'REQUEST_USED_KEYSIGN_FAILURE';

export const REQUEST_MASTER_DOCUMENT_TYPE = 'REQUEST_MASTER_DOCUMENT_TYPE';
export const REQUEST_MASTER_DOCUMENT_TYPE_SUCCESS = 'REQUEST_MASTER_DOCUMENT_TYPE_SUCCESS';
export const REQUEST_MASTER_DOCUMENT_TYPE_FAILURE = 'REQUEST_MASTER_DOCUMENT_TYPE_FAILURE';

export const REQUEST_TOPUP_KEYSIGN = "REQUEST_TOPUP_KEYSIGN";
export const REQUEST_TOPUP_KEYSIGN_SUCCESS = "REQUEST_TOPUP_KEYSIGN_SUCCESS";
export const REQUEST_TOPUP_KEYSIGN_FAILURE = "REQUEST_TOPUP_KEYSIGN_FAILURE";

export const REQUEST_QRCODE_PAYMENT = 'REQUEST_QRCODE_PAYMENT';
export const REQUEST_QRCODE_PAYMENT_SUCCESS = 'REQUEST_QRCODE_PAYMENT_SUCCESS';
export const REQUEST_QRCODE_PAYMENT_FAILURE = 'REQUEST_QRCODE_PAYMENT_FAILURE';

export const REQUEST_STATUS_PAYMENT_QRCODE = 'REQUEST_STATUS_PAYMENT_QRCODE';
export const REQUEST_STATUS_PAYMENT_QRCODE_SUCCESS = 'REQUEST_STATUS_PAYMENT_QRCODE_SUCCESS';
export const REQUEST_STATUS_PAYMENT_QRCODE_FAILURE = 'REQUEST_STATUS_PAYMENT_QRCODE_FAILURE';

export const REQUEST_AGENT = 'REQUEST_AGENT';
export const REQUEST_AGENT_SUCCESS = 'REQUEST_AGENT_SUCCESS';
export const REQUEST_AGENT_FAILURE = 'REQUEST_AGENT_FAILURE';

export const REQUEST_SIGNING_PDF_AGENT = 'REQUEST_SIGNING_PDF_AGENT';
export const REQUEST_SIGNING_PDF_AGENT_SUCCESS = 'REQUEST_SIGNING_PDF_AGENT_SUCCESS';
export const REQUEST_SIGNING_PDF_AGENT_FAILURE = 'REQUEST_SIGNING_PDF_AGENT_FAILURE';
