import Keycloak from 'keycloak-js';
// import {config} from "dotenv";


// config();
// const KeycloakConfig = {
//     "realm": process.env.REACT_APP_KEYCLOAK_REALM,
//     "auth-server-url": process.env.REACT_APP_KEYCLOAK_AUTH_SERVER_URL,
//     "ssl-required": process.env.REACT_APP_KEYCLOAK_SSL_REQUIRED,
//     "resource": process.env.REACT_APP_KEYCLOAK_RESOURCE,
//     "public-client": process.env.REACT_APP_KEYCLOAK_PUBLIC_CLIENT === 'true',
//     "confidential-port": parseInt(process.env.REACT_APP_KEYCLOAK_CONFIDENTIAL_PORT, 10) || 0
// }
// console.log(process.env)
// console.log(KeycloakConfig)
// const _kc = new Keycloak(KeycloakConfig);
// const _kc = new Keycloak('/keycloak.json');
const _kc = new Keycloak('/keycloak-om-jimmy.json');

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */

const initKeycloak = (onAuthenticatedCallback) => {
    _kc.init({
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        pkceMethod: 'S256',
    })
        .then((authenticated) => {
            console.log("authenticated :", authenticated)
            if (!authenticated) {
                console.debug("user is not authenticated..!");
            } else {
                console.debug("user token: " + _kc.token);
            }
            onAuthenticatedCallback();
        })
        .catch((error) => {
            alert(error.error)
            console.log("error :", error)
        });
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;


const isLoggedIn = () => !!_kc.token;
const isLoggedOut = () => !!_kc.token;

const updateToken = (successCallback) =>
    _kc.updateToken(5)
        .then(successCallback)
        .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.name;

const getDetailUser = () => _kc.tokenParsed;

const hasRealmRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));
const hasResourceRole = (roles) => roles.some((role) => _kc.hasResourceRole(role));
const hasRole = (roles) => {
    if (roles) {
        return roles.some(r => {
            const realm = _kc.hasRealmRole(r);
            const resource = _kc.hasResourceRole(r);
            return realm || resource;
        });
    }
    return false;
}

const UserService = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getToken,
    updateToken,
    getUsername,
    hasRealmRole,
    hasResourceRole,
    hasRole,
    isLoggedOut,
    getDetailUser
};

export default UserService;
