import {
    REQUEST_HISTORY_PAYMENT,
    REQUEST_HISTORY_PAYMENT_FAILURE,
    REQUEST_HISTORY_PAYMENT_SUCCESS
} from "../constant/Constant";

const initialState = {
    historyPayment: {
        data: [],
        status: 'idle',
        isLoading: true,
    },
};

export const HistoryPaymentReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case REQUEST_HISTORY_PAYMENT:
            return {
                ...state,
                historyPayment: {
                    data: [],
                    status: 'fetching',
                    isLoading: true,
                },
            };
        case REQUEST_HISTORY_PAYMENT_SUCCESS:
            return {
                ...state,
                historyPayment: {
                    data: payload,
                    status: 'ready',
                    isLoading: false,
                },
            };
        case REQUEST_HISTORY_PAYMENT_FAILURE:
            return {
                ...state,
                historyPayment: {
                    data: [],
                    status: 'fetching',
                    isLoading: true,
                },
            };
        default:
            return state;
    }
};
