import {combineReducers} from 'redux';
import {GetQuotaReducer} from "./GetQuotaReducer";
import {GetMasterPriceReducer} from "./GetMasterPriceReducer";
import {HistoryPaymentReducer} from "./HistoryPaymentReducer";
import {HistoryUsedKeysignReducer} from "./HistoryUsedKeysignReducer";
import {GetMasterDocumentReducer} from "./GetMasterDocumentReducer";
import {TopUpKeysignReducer} from "./TopUpKeysignReducer";
import {GetQrCodePaymentReducer} from "./GetQrCodePaymentReducer";
import {CheckStatusAgentReducer} from "./CheckStatusAgentReducer";
import {TopUpKeysignStatusReducer} from "./TopUpKeysignStatusReducer";
import {SigningPdfAgentReducer} from "./SigningPdfAgentReducer";

const reducer = combineReducers({
    quotaState: GetQuotaReducer,
    masterPriceState: GetMasterPriceReducer,
    historyPaymentState: HistoryPaymentReducer,
    historyUsedKeysignState: HistoryUsedKeysignReducer,
    masterDocumentState: GetMasterDocumentReducer,
    topUpKeysignState: TopUpKeysignReducer,
    topUpKeysignStatusState: TopUpKeysignStatusReducer,
    qrCodePaymentState: GetQrCodePaymentReducer,
    checkAgentState: CheckStatusAgentReducer,
    signingPdfAgentState: SigningPdfAgentReducer,
})

export default reducer;
