import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/styles/style.scss';
import UserService from './services/UserService';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import LoadingScreen from "./utils/LoadingScreen";
import {Provider} from "react-redux";
import {store} from "./services/store";

library.add(fas, far);
const root = ReactDOM.createRoot(document.getElementById('root'));
const AppContent = lazy(() => import('./App'));

function onAuthenticatedCallback() {
    root.render(
        <Provider store={store}>
            <React.StrictMode>
                <Suspense fallback={<LoadingScreen/>}>
                    <AppContent/>
                </Suspense>
            </React.StrictMode>
        </Provider>,
    );
}

UserService.initKeycloak(onAuthenticatedCallback);
