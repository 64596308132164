import {REQUEST_USED_KEYSIGN, REQUEST_USED_KEYSIGN_FAILURE, REQUEST_USED_KEYSIGN_SUCCESS} from "../constant/Constant";

const initialState = {
    historyPayment: {
        data: [],
        status: 'idle',
        isLoading: true,
    },
};

export const HistoryUsedKeysignReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case REQUEST_USED_KEYSIGN:
            return {
                ...state,
                historyPayment: {
                    data: [],
                    status: 'fetching',
                    isLoading: true,
                },
            };
        case REQUEST_USED_KEYSIGN_SUCCESS:
            return {
                ...state,
                historyPayment: {
                    data: payload,
                    status: 'ready',
                    isLoading: false,
                },
            };
        case REQUEST_USED_KEYSIGN_FAILURE:
            return {
                ...state,
                historyPayment: {
                    data: [],
                    status: 'fetching',
                    isLoading: true,
                },
            };
        default:
            return state;
    }
};
