import {
    REQUEST_MASTER_PRICE,
    REQUEST_MASTER_PRICE_FAILURE,
    REQUEST_MASTER_PRICE_SUCCESS
} from "../constant/Constant";

const initialState = {
    masterPrice: {
        data: [],
        status: 'idle',
        isLoading: true,
    },
};

export const GetMasterPriceReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case REQUEST_MASTER_PRICE:
            return {
                ...state,
                masterPrice: {
                    data: [],
                    status: 'fetching',
                    isLoading: true,
                },
            };
        case REQUEST_MASTER_PRICE_SUCCESS:
            return {
                ...state,
                masterPrice: {
                    data: payload,
                    status: 'ready',
                    isLoading: false,
                },
            };
        case REQUEST_MASTER_PRICE_FAILURE:
            return {
                ...state,
                masterPrice: {
                    data: [],
                    status: 'fetching',
                    isLoading: true,
                },
            };
        default:
            return state;
    }
};
