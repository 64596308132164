import {
    REQUEST_TOPUP_KEYSIGN,
    REQUEST_TOPUP_KEYSIGN_FAILURE,
    REQUEST_TOPUP_KEYSIGN_SUCCESS
} from "../constant/Constant";

const initialState = {
    topUpKeysign: {
        data: [],
        status: 'idle',
        isLoading: true,
    },
};

export const TopUpKeysignReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case REQUEST_TOPUP_KEYSIGN:
            return {
                ...state,
                topUpKeysign: {
                    data: [],
                    status: 'fetching',
                    isLoading: true,
                },
            };
        case REQUEST_TOPUP_KEYSIGN_SUCCESS:
            return {
                ...state,
                topUpKeysign: {
                    data: payload,
                    status: 'ready',
                    isLoading: false,
                },
            };
        case REQUEST_TOPUP_KEYSIGN_FAILURE:
            return {
                ...state,
                topUpKeysign: {
                    data: [],
                    status: 'fetching',
                    isLoading: true,
                },
            };
        default:
            return state;
    }
};
