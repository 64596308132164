import {
    REQUEST_SIGNING_PDF_AGENT,
    REQUEST_SIGNING_PDF_AGENT_FAILURE,
    REQUEST_SIGNING_PDF_AGENT_SUCCESS
} from "../constant/Constant";

const initialState = {
    signingPdfAgent: {
        data: [],
        status: 'idle',
        isLoading: true,
    },
};

export const SigningPdfAgentReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case REQUEST_SIGNING_PDF_AGENT:
            return {
                ...state,
                signingPdfAgent: {
                    data: [],
                    status: 'fetching',
                    isLoading: true,
                },
            };
        case REQUEST_SIGNING_PDF_AGENT_SUCCESS:
            return {
                ...state,
                signingPdfAgent: {
                    data: payload,
                    status: 'ready',
                    isLoading: false,
                },
            };
        case REQUEST_SIGNING_PDF_AGENT_FAILURE:
            return {
                ...state,
                signingPdfAgent: {
                    data: [],
                    status: 'failure',
                    isLoading: true,
                },
            };
        default:
            return state;
    }
};
