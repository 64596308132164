import {
    REQUEST_STATUS_PAYMENT_QRCODE,
    REQUEST_STATUS_PAYMENT_QRCODE_FAILURE,
    REQUEST_STATUS_PAYMENT_QRCODE_SUCCESS
} from "../constant/Constant";

const initialState = {
    topUpKeysignStatus: {
        data: [],
        status: 'idle',
        isLoading: true,
    },
};

export const TopUpKeysignStatusReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case REQUEST_STATUS_PAYMENT_QRCODE:
            return {
                ...state,
                topUpKeysignStatus: {
                    data: [],
                    status: 'fetching',
                    isLoading: true,
                },
            };
        case REQUEST_STATUS_PAYMENT_QRCODE_SUCCESS:
            return {
                ...state,
                topUpKeysignStatus: {
                    data: payload,
                    status: 'ready',
                    isLoading: false,
                },
            };
        case REQUEST_STATUS_PAYMENT_QRCODE_FAILURE:
            return {
                ...state,
                topUpKeysignStatus: {
                    data: [],
                    status: 'fetching',
                    isLoading: true,
                },
            };
        default:
            return state;
    }
};
