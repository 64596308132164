import React from 'react'
import logoKeySign from "../assets/image/logo-keysign-new.png"

const LoadingScreen = () => {

    return (
        <>
            <div className={'loader'}></div>
            <div className={'image-loader'}>
                <img src={logoKeySign} aria-hidden alt="loder image"/>
            </div>
        </>
    );
}

export default LoadingScreen;
