import {REQUEST_AGENT, REQUEST_AGENT_FAILURE, REQUEST_AGENT_SUCCESS} from "../constant/Constant";

const initialState = {
    checkAgent: {
        data: [],
        status: 'idle',
        isLoading: true,
    },
};

export const CheckStatusAgentReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case REQUEST_AGENT:
            return {
                ...state,
                checkAgent: {
                    data: [],
                    status: 'fetching',
                    isLoading: true,
                },
            };
        case REQUEST_AGENT_SUCCESS:
            return {
                ...state,
                checkAgent: {
                    data: payload,
                    status: 'ready',
                    isLoading: false,
                },
            };
        case REQUEST_AGENT_FAILURE:
            return {
                ...state,
                checkAgent: {
                    data: [],
                    status: 'failure',
                    isLoading: true,
                },
            };
        default:
            return state;
    }
};
